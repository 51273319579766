<template>
  <Dialog
  header="Header"
  v-model:visible="isVisible"
  :modal="true"
  >
    <template #header>
      <h3>Editar precio</h3>
    </template>
    <div class="w-full mb-2">
      <label for="price">Precio</label>
      <input
      id="price"
      v-model="model.price"
      type="number"
      class="form-control"
      >
    </div>
    <!-- <div class="mb-2">
      <label for="justification">Justificacion</label>
      <textarea
      id="justification"
      v-model="model.justification"
      rows="3"
      class="form-control"
      />
    </div> -->
    <template #footer>
      <Button
      label="No"
      icon="pi pi-times"
      class="p-button-text"
      @click="toggle"
      />
      <Button
      label="Si"
      icon="pi pi-check"
      autofocus
      :loading="isLoading"
      @click="handleSubmit"
      />
    </template>
  </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog'
import useProductModal from './useProductModal'
import { watch } from 'vue'

export default {
  name: 'productModal',
  components: {
    Dialog
  },
  setup (props, { emit }) {
    const {
      isVisible,
      model,
      supplier,
      isLoading,
      toggle,
      handleSubmit
    } = useProductModal()

    watch(() => supplier.value.id, (current) => {
      model.value.price = supplier.value.price
    })
    watch(() => isLoading.value, (current) => {
      if (!current) {
        emit('refresh')
      }
    })

    return {
      model,
      isVisible,
      isLoading,
      toggle,
      handleSubmit
    }
  }

}
</script>
